//
// Modal
//

.modal-header,
.modal-footer {
    border-width: 1px;
}


// Fluid modal

.modal-fluid {
    .modal-dialog {
        margin-top: 0;
        margin-bottom: 0;
    }
    .modal-content {
        border-radius: 0;
    }
}


// Background color variations

@each $color, $value in $theme-colors {
    .modal-#{$color} {
        @include modal-variant($value);
    }
}
