.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.min-height-100 {
  min-height: 100px;
}

.min-height-page {
  min-height: 100px;
}

textarea {
  min-height: 100px;
  resize: none;
}

.page {
  min-height: 100vh;
  position: relative;
}

.required {
  &::after {
    color: var(--danger);
    content: '*';
    margin-left: 2px;
  }
}

.nav-tabs {
  border-width: 2px;

  .nav-item {
    .nav-link {
      border: none;
      color: var(--text-default);
      font-size: 18px;
      min-width: 100px;
      padding-left: 20px;
      padding-right: 20px;
      text-align: center;

      &:hover {
        border: none;
        border-bottom: 4px solid var(--primary);
      }

      &.active {
        background: inherit;
        border: none;
        border-bottom: 4px solid var(--primary);
        color: var(--primary);
      }
    }
  }
}

.rounded-top-left-1 {
  border-top-left-radius: 1px !important;
}
.rounded-top-right-1 {
  border-top-right-radius: 1px !important;
}
.rounded-bottom-left-1 {
  border-bottom-left-radius: 1px !important;
}
.rounded-bottom-right-1 {
  border-bottom-right-radius: 1px !important;
}
.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.loading-indicator-container {
  z-index: 10;

  &.overlay {
    background-color: rgba(0, 0, 0, 0.2);
    height: 100%;
    position: absolute;
    width: 100%;
  }

  .loading-indicator {
    left: 48%;
    position: absolute;
    top: 50%;
  
    .spinner-border {
      height: 3rem;
      width: 3rem;
    }
  }
}

.horizontal-scroll {
  display: flex;
  flex-wrap: nowrap;
  overflow: -moz-scrollbars-none;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */

  &::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
  }
}

.pill {
  border-radius: 10px;
  cursor: default;
  font-weight: 500;
  font-size: 14px;
  padding: 5px 15px;

  &.small {
    font-size: 12px;
  }

  &.default {
    background-color: var(--info);
    color: var(--dark);
  }

  &.primary {
    background-color: var(--primary);
    color: var(--white);
  }

  &.secondary {
    background-color: var(--secondary);
    color: var(--white);
  }

  &.danger {
    background-color: var(--danger);
    color: var(--white);
  }

  &.info {
    background-color: var(--info);
    color: var(--white);
  }

  &.success {
    background-color: var(--success);
    color: var(--white);
  }
}

.default-link {
  color: var(--dark);

  &:hover {
    color: var(--dark);
    text-decoration: underline;
  }
}