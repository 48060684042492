//
// Brand buttons
//


// Color variations

@each $color, $value in $brand-colors {
    .btn-#{$color} {
        @include button-variant($value, $value);
    }
}

.btn.no-hover {
    &:hover,
    &:active,
    &:focus {
        box-shadow: none;
        transform:  none;
    }
}
